import { createVuetify, ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";

const theme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#22A699", // Set your desired primary color
    secondary: "#F2BE22", // Set your desired secondary color
  },
};

export default createVuetify({
  directives,
  components,
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: "customTheme",
    themes: {
      customTheme: theme,
    },
  },
});
