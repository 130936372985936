
import { defineComponent, reactive } from "vue";
import { apiClient } from "@/utils/apiClient";

interface AppData {
  apiClient: typeof apiClient;
  serviceAvailable: boolean;
  failed: boolean;
}

export default defineComponent({
  name: "App",
  data(): AppData {
    return {
      apiClient: apiClient,
      serviceAvailable: false,
      failed: false,
    };
  },
  methods: {
    reactive,
    logout() {
      if (this.apiClient.isLoggedIn()) {
        localStorage.removeItem("userName");
        this.apiClient.userName = "";
      }
      this.$router.push("/login");
    },
  },
});
