import axios, { AxiosInstance } from "axios";
import { reactive } from "vue";

export interface LoginInfo {
  userName: string;
  apiKey: string;
}

export interface MovieInfo {
  title: string;
  year: number;
  imdbId: string;
  posterUrl: string | null;
}

export interface MovieSelection {
  id: number;
  movieInfo: MovieInfo;
  watchDate: string;
  dateSelected: string;
  userName: string;
}

interface User {
  id: number;
  name: string;
  movies: MovieSelection[] | null;
}

export interface GetUserMoviesResponse {
  user: User;
  movies: MovieSelection[];
}

export interface GetMoviesResponse {
  movies: MovieSelection[];
}

export interface MovieSearchResult {
  movies: MovieInfo[];
}

export interface MovieDetails {
  imdbId: string;
  title: string;
  year: string;
  rated: string;
  released: string;
  runtime: string;
  genre: string;
  director: string;
  writer: string;
  actors: string;
  plot: string;
  language: string;
  country: string;
  awards: string;
  posterUrl: string | null;
}

interface AddMovieToUserResponse {
  user: User;
  weeks_to_wait: number;
}

interface AddMovieRequest {
  user_name: string;
  movie: MovieInfo;
  date: string;
}

export interface LoginResponse {
  serviceOk: boolean;
  createdUser: boolean;
}

export interface NextDateResponse {
  nextDate: string;
  afterDate: string;
}

const BASE_URL: string =
  "https://4zx1pl3na8.execute-api.us-east-2.amazonaws.com/prod2";

export class ApiClient {
  private axiosInstance: AxiosInstance;
  public userName: string;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
    });
    this.userName = "";
  }

  /**
   * Set API key
   */
  public async login(loginInfo: LoginInfo): Promise<LoginResponse> {
    // Set API Key and check response.
    this.axiosInstance.defaults.headers["x-api-key"] = loginInfo.apiKey;
    const response = await this.axiosInstance.get<string>("/healthcheck");
    const success = response.data == "OK";

    // Validate the user, create if necessary.
    let createdUser = false;
    if (success) {
      this.axiosInstance
        .get(`/users/${loginInfo.userName}`)
        .then((response) => {
          if (response.status == 404) {
            console.log("Creating user.");
            this.createUser(loginInfo.userName).then((response) => {
              alert(`User not found. Created a new user: ${response.name}.`);
            });
            createdUser = true;
          }
        })

        .catch((error) => {
          if (error.response.status == 404) {
            console.log("Creating user.");
            this.createUser(loginInfo.userName);
            createdUser = true;
          }
        });
      this.userName = loginInfo.userName;
    }
    return { serviceOk: success, createdUser: createdUser };
  }

  /**
   * Indicate if the service is logged in.
   */
  public isLoggedIn(): boolean {
    return this.userName != "" && this.userName != null;
  }

  /**
   * List all movies
   */
  public async listMovies(): Promise<GetMoviesResponse> {
    const response = await this.axiosInstance.get<GetMoviesResponse>("/movies");
    return response.data;
  }

  /**
   * Create a new user
   * @param name
   */
  public async createUser(name: string): Promise<User> {
    const response = await this.axiosInstance.post<User>("/users", { name });
    return response.data;
  }

  /**
   * Add a movie to the queue
   * @param movie - Movie to add
   * @param watchDate - Date to watch the movie
   */
  public async addMovie(
    movie: MovieInfo,
    watchDate: string
  ): Promise<AddMovieToUserResponse> {
    const movieRequest: AddMovieRequest = {
      user_name: this.userName,
      movie: movie,
      date: watchDate,
    };
    const response = await this.axiosInstance.post<AddMovieToUserResponse>(
      "/movies",
      movieRequest
    );
    return response.data;
  }

  /**
   * Look up the next available date for a movie
   * @param after - Optional date to start the search
   */
  public async getNextAvailableDate(
    after: string | null = null
  ): Promise<string> {
    const params = {
      after,
    };
    const response = await this.axiosInstance.get<NextDateResponse>(
      `/movies/next_available`,
      { params }
    );
    return response.data.nextDate;
  }

  /**
   * Search for movies via the OMDB API
   * @param title
   * @param year
   */
  public async searchMovies(
    title: string,
    year?: string
  ): Promise<MovieSearchResult> {
    const params = {
      title,
      year,
    };
    const response = await this.axiosInstance.get<MovieSearchResult>(
      "/movies/search",
      { params }
    );
    return response.data;
  }
}

export const apiClient = reactive(new ApiClient());
