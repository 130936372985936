import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { apiClient } from "@/utils/apiClient";
const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/UpComing.vue"),
  },
  {
    path: "/submit",
    name: "Submit",
    component: () => import("@/views/SubmitMovie.vue"),
  },
  {
    path: "/archive",
    name: "Archive",
    component: () => import("@/views/Archive.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = apiClient.isLoggedIn();
  if (to.name !== "Login" && !loggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
